.brand__lockup img {
	height: 84px;
}

.title-bar {
	font-family: $wcm-regular;
	padding-top: 5px;

	@include breakpoint($xs) {
		font-size: 30px;
	}
}

$primary-nav-width: 12%; // 14.2%; // 20%;

#primary-nav .level-1 {
	width: 14.2%; //$primary-nav-width;
	font-size: 14px;

	@include breakpoint($md) {
		width: 11%;

		&:nth-child(4) {
			width: 19.3%;
		}

		&:nth-child(5) {
			width: 17.7%;
		}

		&:nth-child(6) {
			width: 14.4%;
		}

		&:last-child {
			width: 15.5%;
		}
	}

	@include breakpoint($lg) {
		font-size: 16px;

		&:nth-child(4) {
			width: 17.7%;
		}

		&:nth-child(5) {
			width: 17.7%;
		}

		&:nth-child(6) {
			width: 12%;
		}

		&:last-child {
			width: 16.5%;
		}

		width: $primary-nav-width; //Customize me based on content!
	}

	padding: 8px 1px;
}

.block-menu-block {
	@include breakpoint($md) {
		-moz-column-count: 3;
		-webkit-column-count: 3;
		-ms-column-count: 3;
		-o-column-count: 3;
		column-count: 3;
		-webkit-column-gap: 15px;
		-moz-column-gap: 15px;
		-ms-column-gap: 15px;
		-o-column-gap: 15px;
		column-gap: 15px;
		margin-bottom: 1em;
	}
}

.pane-wcmc-second-level-nav {
	clear: both;
}

.wcm-cta__tile:nth-child(3) {
	border-width: 1px 1px 1px 0;
}

.wcm-cta__tile:nth-child(4) {
	border-width: 1px 0 1px 0;
}

.wcm-cta__tile:nth-child(5) {
	border-width: 0 1px 0 0;
}

.wcm-cta__tile:hover {
	border-width: 1px;
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
	max-width: none;
	height: 50px;
	border: 1px solid $wcm-border-gray;
	box-shadow: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 15px top 50%;
	background-size: 20px 20px;
	padding-right: 50px;
}

.btn--wcm {
	padding: 10px 20px;

	&:before {
		line-height: 2.5;
	}
}

.btn--small {

	&:hover,
	&:focus {
		background-color: $wcm-red;
		border-color: $wcm-red;
	}
}

/*.site-main .external-link {
	&:after {
		display: inline-block;
		content: '' !important;
		background-image: url('/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg');
		background-size: 15px 15px;
		height: 15px;
		width: 15px;
		margin-left: 6px;
		position: relative;
		top: 2px;
	}
}*/

.file-icon[alt="PDF icon"] {
	background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/pdf.svg);
	background-repeat: no-repeat;
	background-size: 20px 20px;
	height: 20px;
	width: 20px;
	position: relative;
	padding-left: 25px;
}

.address-location {
	cursor: pointer;
}

.address-location-wrapper {
	// margin-bottom: 20px;
}

.page-image-callout {
	@include breakpoint($sm) {
		width: 50%;
		float: left;
		padding-right: 20px;
	}

	margin-bottom: 30px;
	margin-top: 30px;

	&.wcm-bright-orange {
		.fieldable-panels-pane {
			background: $wcm-bright-orange;
		}
	}

	&.wcm-dark-orange {
		.fieldable-panels-pane {
			background: $wcm-dark-orange;
		}
	}

	&.last {
		@include breakpoint($sm) {
			padding-left: 20px;
			padding-right: 0;
		}
	}

	&:hover {
		img {
			@include scale(1.1);
		}
	}

	img {
		mix-blend-mode: multiply;
	}

	// .pane-bundle-image {
	a {
		border-bottom: none;
	}

	img {
		@include transition(all 0.25s);
		width: 100%;
		margin-bottom: 0 !important;
	}

	.fieldable-panels-pane {
		position: relative;

		div {
			&:first-child div {
				overflow: hidden;
				position: relative;

				&:after {
					background: $wcm-dark-orange;
					width: 45px;
					height: 45px;
					color: $wcm-white;
					content: '\e802';
					@include fontello();
					position: absolute;
					bottom: 0;
					right: 0;
					line-height: 2em;
					font-size: 25px;
					padding-left: 3px;
				}
			}

			&:last-child div {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;

				a {
					position: absolute;
					top: 0;
					color: $wcm-white;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					/* align horizontal */
					align-items: center;
					/* align vertical */
					font-family: $wcm-bold;
					font-size: 22px;
					text-align: center;
					padding: 0 20px;
				}
			}
		}
	}
}

.page-center-callout {
	text-align: center;
	margin: 0 auto;

	@include breakpoint($sm) {
		width: 75%;
	}
}

.page-callout {
	margin-top: 30px;
	margin-bottom: 20px;
	border-left: 1px solid $wcm-dark-orange;
	background: $wcm-bg-gray;

	position: relative;
	padding: 15px 0 10px 25px;
	clear: both;

	h3 {
		margin-top: 10px;
	}
}

.events-calendar-callout {
	margin-bottom: 20px;
	border-left: 1px solid $wcm-dark-orange;
	background: $wcm-bg-gray;

	position: relative;
	padding: 15px 0 10px 25px;
	clear: both;

	h3 {
		margin-top: 10px;
	}
}

.services-patientcare-callout {
	text-align: center;
	border-bottom: 1px solid $wcm-border-gray;
	margin-bottom: 20px;
}

.ophthalmology-phone-number {
	display: block;
	font-size: 26px;
	margin-bottom: 20px;
	text-align: center;
	font-family: $wcm-bold;
}


.page-news {
	.panel-twocol {
		h1 {
			margin-bottom: 20px;
		}
	}
}

.pane-node-field-clinical-service-locations {
	&:before {
		content: '';
		display: block;
		border-top: 1px solid $wcm-border-gray;
		width: 80%;
		margin: 0 auto;

	}

	#locations-map {
		margin-bottom: 20px;
	}

	.container {
		margin-left: 0;
		margin-right: 0;

		.col-sm-6 {
			margin-bottom: 20px;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				background: url(../images/icon-location.png) no-repeat;
				width: 20px;
				height: 30px;
				background-size: 18px;
			}

			.address-location {
				padding-left: 10px;
			}
		}
	}

	#locations-map {
		height: 280px;
		width: 100%;
	}

	h3 {
		text-align: center;
		margin-bottom: 10px;
	}
}

.pane-locations-panel-pane-3 {

	#locations-map {
		margin-bottom: 20px;
		height: 280px;
		width: 100%;
	}

	.col-sm-6 {
		margin-bottom: 20px;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			background: url(../images/icon-location.png) no-repeat;
			width: 20px;
			height: 30px;
			background-size: 18px;
		}

		.address-location {
			padding-left: 10px;
		}
	}

	h3 {
		text-align: center;
		margin-bottom: 10px;
	}
}

.pane-clinical-service-brochures-panel-pane-1 {
	margin-top: 30px;
	clear: both;

	&:before {
		content: '';
		display: block;
		border-top: 1px solid $wcm-border-gray;
		width: 80%;
		margin: 0 auto;

	}

	h3 {
		text-align: center;
		margin-bottom: 10px;
	}

	.views-field-title {
		a {
			position: relative;
			display: block;
			border-bottom: none;
			padding: 10px 0 15px 45px;
			z-index: 1;

			&:before {
				content: '';
				display: inline-block;
				background-repeat: no-repeat;

				background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/pdf.svg);
				background-repeat: no-repeat;
				background-size: 27px 27px;
				height: 27px;
				width: 27px;

				/*background: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
                // background: url(../../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg);
                width: 27px;
                height: 27px;
                background-position: -31px -92px;
                // background-size: 1900%;
                // calculation: sprite site: 1264px 304px
                // desired size = 25 / original size= 64
                // bg width = 1264 * 0.390625
                // bg height = 304 * 0.390625
                background-size: 494px 119px;*/
				margin-right: 10px;
				position: absolute;
				left: 5px;
				top: 10px;
			}

			&:hover {
				text-decoration: none;

				&:before {
					background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/download.svg);
					// background-size: 494px 119px;
					// background-position: -62px -29px !important;
				}
			}
		}
	}
}

.pane-profiles-panel-pane-3 {
	@include clearfix();
	margin-top: 30px;

	&:before {
		content: '';
		display: block;
		border-top: 1px solid $wcm-border-gray;
		width: 80%;
		margin: 0 auto;

	}

	h3 {
		text-align: center;
		margin-bottom: 30px;
	}

	.views-row {
		margin-bottom: 20px;
		margin-left: 0 !important;
		margin-right: 0 !important;

		&.views-row-odd {
			@include breakpoint($sm) {
				padding-left: 0;
				clear: left;
			}
		}

		&.views-row-even {
			@include breakpoint($sm) {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.col-sm-5 {
			padding-left: 0;
			padding-right: 0;

			img {
				@include breakpoint($sm) {
					width: 100%;
				}

				margin-bottom: 10px;
			}
		}

		.col-sm-7 {

			@include breakpoint($xs) {
				padding-left: 0;
				padding-right: 0;
			}

			.item-list {
				ul {
					margin-bottom: 20px;
					list-style: none;

					li {
						margin-left: 0;
						font-style: italic;
					}
				}
			}
		}

		.btn--small {
			margin-bottom: 10px;
			white-space: inherit;
		}
	}
}

.view-news {
	.view-filters {
		.views-exposed-form {

			.views-exposed-widgets {
				margin: 15px 0 30px 0;
			}

			.views-exposed-widget {
				float: none;
			}

			label {
				font-size: 13px;
			}
		}
	}

	.view-teaser {
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;

		.teaser-image img {
			width: auto;
			max-width: none;
			float: none;

			@include breakpoint($sm) {
				float: right;
				margin-top: 0.25em;
				margin-left: 0.5em;
				margin-bottom: 0;
				width: 25%;
				max-width: 25%;
			}

			// }

			/*img {
	            float: none;
	            max-width: none;
	            width: 100%;
	            margin: 0;
	        }*/
		}
	}
}

.pane-news-panel-pane-1,
.pane-news-panel-pane-4 {
	.views-field-field-news-spotlight-title {
		margin-bottom: 10px;
	}
}

.pane-news-panel-pane-3 {
	.view-teaser {
		padding-bottom: 10px;

		&:last-child {
			border-bottom: none;
		}
	}
}

.node-type-news-post {
	.pane-node-field-news-spotlight-title {
		font-style: italic;
		margin-bottom: 10px;
	}

	.field-name-field-news-category {
		position: relative;
		padding-left: 25px;

		&:before {
			content: '';
			background: url(../images/tag.svg);
			background-size: 20px 20px;
			height: 20px;
			width: 20px;
			position: absolute;
			left: 0;
		}
	}
}

.view-news {
	.views-field-field-news-source-link-1 {
		a:not(.external-link):after {
			content: '\e80d';
			@include fontello();
			padding-left: 10px;
			font-size: 60%;
		}
	}
}

.view-profiles {
	.select2-container {
		display: none;
	}

	.view-content {
		margin-top: 30px;

		h3 {
			display: none;
		}

		.views-row {
			@include clearfix();
			margin-bottom: 30px;
			margin-left: -20px;
			margin-right: -20px;

			h3 {
				display: block;
			}

			.wcmc-profile-table {
				@include breakpoint($sm) {
					width: 100%;
				}

				margin-bottom: 20px;
			}

			.medium {
				margin-bottom: 20px;
			}

			.btn--small {
				margin-bottom: 10px;

				@include breakpoint($sm) {
					width: 100%;
				}
			}

			.profile-services {
				margin-top: 10px;
				border-bottom: 1px solid $wcm-border-gray;

				.field-content:last-child {
					margin-bottom: 10px;
				}

				ul {
					margin-bottom: 0;
				}
			}

			.profile-titles {
				display: block;
				margin: 10px 0;
			}
		}
	}

	.views-widget-filter-field_person_type_taxonomy_tid {
		display: block;
		width: 100%;
		padding-right: 0;

		a {
			font-size: 16px;

			@include breakpoint($lg) {
				font-size: 18px;
			}

			width: 25%;
			display: inline-block;
			border-bottom: 1px solid $wcm-border-gray;
			float: left;
			padding: 20px 0;
			text-align: center;
			color: $wcm-med-gray;
			font-weight: 700;

			@include breakpoint($xs) {
				font-size: 15px;
				width: 50%;
			}

			&.active {
				border-top: 1px solid $wcm-dark-orange;
				border-bottom: none;
				border-left: 1px solid $wcm-border-gray;
				border-right: 1px solid $wcm-border-gray;
				color: $wcm-dark-orange;
			}
		}

		.form-group {
			@include breakpoint($xs) {
				a {
					border: 1px solid $wcm-border-gray;

					&.active {
						border-top: 1px solid $wcm-dark-orange;
					}
				}
			}
		}
	}
}

.node-type-event {
	.pane-node-field-event-date {
		color: $wcm-med-gray;
		font-family: $wcm-bold;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 13px;
		// margin-top: 5px;
	}
}

.view-events {

	// .views-exposed-form .views-exposed-widget {
	//   float: none;

	//   label {
	//     font-size: 13px;
	//   }
	// }

	.views-row {
		@include clearfix();
		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;

		.views-field-field-event-date {
			color: $wcm-med-gray;
			font-family: $wcm-bold;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: 13px;
			margin-top: 5px;
		}

		.views-field-field-event-image {
			img {
				float: none;
				max-width: none;
				width: auto;
				margin-right: .5em;
				margin-bottom: .25em;
				margin-top: .25em;

				@include breakpoint($sm) {
					float: right;
					width: 25%;
					max-width: 25%;
					margin-left: 10px;
					margin-right: 0;
				}
			}
		}
	}
}

.pane-events-panel-pane-2 {
	.views-row {
		padding-bottom: 10px;

		&:last-child {
			border-bottom: none;
		}
	}
}


.view-clinical-trials {

	.views-row {

		border-bottom: 1px solid $wcm-border-gray;
		padding-bottom: 20px;
		margin-bottom: 15px;

		.views-field-title,
		.trial-title {
			padding-bottom: 10px;
			font-size: 17px;
		}

		.views-field-field-trial-primary-investigator,
		.views-field-field-trial-contact-email,
		.views-field-field-trial-contact-name,
		.views-field-field-trial-contact-phone,
		.views-field {
			padding-bottom: 2px;

			.views-label {
				font-weight: 600;
			}

			.field-content {
				display: inline;
			}

			//   :not(h4).field-content {
			//     display: inline;
			//   }
		}

		.views-field-nothing {
			padding-top: 10px;
		}
	}

	a.pc-btn-small {
		display: inline-block;
		background-color: $wcm-dark-orange;
		border-radius: 7px;
		height: 30px;
		line-height: 30px;
		padding: 0 14px;
		color: #fff;
		margin-right: 10px;
		font-size: 13px;
	}
}

/*.pane-carousel-panel-pane-1, .pane-carousel-panel-pane-4 {
	.slide-text {
		display: none;
	}
}*/
