/*****************************/
.front:not(.panels-ipe-editing) {
	/*.container-top .content-row {
		// position: absolute;
	}*/
	.container-top {
		padding: 50px 20px;
		/*height: 175px;
	    @include breakpoint($sm) {
	        height: 350px;
	    }*/

	    /*.content-row {
	    	@include v-center();
	    }*/
	}
	.container-top .content-header-image {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		margin: 0;
		// height: 350px;
		.content-left {
			height: 100%;
		}
	}

	.hero-image--full {
		position: absolute;
		height: 100%;
	}
}
.front {
	.hero-image--full {
	    width: 100%;
	    // height: 175px;
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: top center;
	    position: relative;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    // height: 100%;
		height: 350px;

	    // margin: 0 0 20px;
	    /*@include breakpoint($sm) {
	        height: 350px;
	    }*/

	}

	.container-top {
		position: relative;

		.content-row {
			// @include v-center();

			.container-fluid {
				max-width: 1350px;
				@include breakpoint($xs) {
					// padding-left: 0;
					// padding-right: 0;
				}
			}
		}
		.header-callout-wrapper {
			@include clearfix();
			
			&:before {
				content: " ";
				display: block;
			}

			@include breakpoint($sm) {
				float: right;
				display: -webkit-box;
			    display: -webkit-flex;
			    display: -ms-flexbox;
			    display: flex;
			    -webkit-flex-wrap: wrap;
			    -ms-flex-wrap: wrap;
			    flex-wrap: wrap;
			}

			.header-callout-row {
				@include breakpoint($sm) {
					width: 250px;
					float: left;
					margin-right: 2px;
				}
				// margin-bottom: 2px;

				&:last-child {
					@include breakpoint($sm) {
						width: 210px;
					}
					.header-callout {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.header-callout {
			background: rgba(255, 255, 255, 0.9);
			@include breakpoint($sm) {
				float: left;
				width: 250px;
			}
			padding: 20px;
			position: relative;
			// margin-right: 2px;
			// margin-bottom: 2px;
			-webkit-box-shadow: 3px 3px 5px 0 $wcm-med-gray;
			box-shadow: 3px 3px 5px 0 $wcm-med-gray;

			display: -webkit-box;
		    display: -webkit-flex;
		    display: -ms-flexbox;
		    display: flex;
		    -webkit-box-orient: vertical;
		    -webkit-box-direction: normal;
		    -webkit-flex-direction: column;
		    -ms-flex-direction: column;
		    flex-direction: column;

		    a {
		    	font: 0/0 a;
		    	position: absolute;
		    	width: 100%;
		    	height: 100%;
		    	top: 0;
		    	left: 0;
		    	border-bottom: none;

		    	&:hover {
		    		border: 1px solid $wcm-dark-orange;
		    	}
		    }
		    br {
		    	display: none;
		    }

			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				top: 5px;
				right: 5px;
				border-style: solid;
				border-width: 0 7px 7px 0;
				border-color: transparent $wcm-dark-orange transparent transparent;

			}

			h3 {
				margin-top: 0;
			}

			&:first-child {
				margin-bottom: 2px;
			}
			@include breakpoint($xs) {
				&:last-child {
					margin-bottom: 2px;
				}
			}
			/*&:last-child {
				// float: right;
				width: 210px;
			}*/
		}
	}
}

.home-phone-num {
	background: $wcm-bg-gray;
	text-align: center;
	padding-top: 25px;
	padding-bottom: 25px;

	.phone {
		display: inline-block;
	    font-size: 30px;
	    border-bottom: 1px solid $wcm-border-gray;
	    margin-bottom: 10px;
	    padding: 0 20px 5px;
	    text-align: center;

	    /*&:after {
	    	content: '';
	    	position: relative;
	    	border-bottom: 1px solid red;
	    	display: block;
	    	width: 120%;
	    	left: 10%;
	    	// padding: 0 20px;
	    }*/
	}

	span {
		color: $wcm-med-gray;
		font-weight: 700;
		display: block;
		letter-spacing: 2px;

		&.appt-link {
			font-weight: 400;
			letter-spacing: 0;
		}
	}
}

.content-middle {
	.col-sm-8 {
		float: none;
		margin: 0 auto;

		.welcome-message {
			text-align: center;
			h3 {
				margin-bottom: 20px;
			}
		}
	}
}

.content-locations {
	padding-top: 50px;
	padding-bottom: 50px;

	.pane-locations-panel-pane-1 {
		.pane-title {
			margin-top: 0;
			margin-bottom: 20px;
			text-align: center;
		}
	}

	.views-row {
		padding-bottom: 20px;
		&:last-child {
			padding-bottom: 0;
		}
	}


	.locations-list {
		height: 100%;
		padding: 30px;
		border: 1px solid $wcm-border-gray;

		a {
			position: relative;
			border-bottom: none;
			&:after {
	            display: inline-block;
	            content: '' !important;
	            background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/external-link.svg);
	            background-size: 15px 15px;
	            height: 15px;
	            width: 15px;
	            position: relative;
	            // right: 0;
	            margin-left: 5px;
	            top: 2px;
	        }
		}
	}

	div[class*='col-sm-'] {
		@include breakpoint($sm) {
			padding-left: 0;
			padding-right: 0;
		}

        @include breakpoint($xs) {
            width: 100%;
        }
	}
}

#locations-map {
	width: 100%;
	height: 250px;
    @include breakpoint($sm) {
    	margin: 20px 0;
        height: 440px;
    }

}

.middle-column {
	.col-sm-6 {
		margin-bottom: 30px;

		.pane-bundle-text {
			width: 100%;
			padding: 0;
            @include breakpoint($sm) {
                position: absolute;
				top: 50%;
				-webkit-transform: translate(0%, -50%);
    			-ms-transform: translate(0%, -50%);
   				transform: translate(0%, -50%);
				padding: 0 40px 0 0; 
            }
		}

		.pane-bundle-image {
			img {
				width: 100%;
				margin-bottom: 0;
			}
			.fieldable-panels-pane div {
				position: relative;
				&:after {
					background: $wcm-dark-orange;
					width: 45px;
					height: 45px;
					color: $wcm-white;
					content: '\e802';
					@include fontello();
					position: absolute;
					bottom: 0;
					right: 0;
					line-height: 2em;
					font-size: 25px;
					padding-left: 3px;
				}
			}
		}
	}
}

.callouts-container {
	margin: 50px auto 0;
	.callouts-wrapper {
		float: none;
		margin: 0 auto;
	}
	.homepage-callouts {
		text-align: center;
		.cta-icon {
			display: block;
			margin: 10px auto;
			width: 64px;
			height: 64px;
			color: $wcm-dark-orange;
			fill: $wcm-bright-orange;
		}

		&:after {
			content: '';
			display: block;
			border-top: 1px solid $wcm-border-gray;
			width: 60%;
			margin: 40px auto;
		}
	}
}


.bottom-column {
	padding: 20px 0;
	border-top: 1px solid $wcm-border-gray;

	.col-sm-4 {
		margin-bottom: 30px;

		h3 {
			color: $wcm-dark-gray;
		}
	}
}