//Calendar Styles
.view .date-nav-wrapper .date-prev{
	right: 119px;
	
	@include breakpoint($xs) {
	  right: auto;
	}
}

.view .date-nav-wrapper .date-heading h3 {
	text-align: left;
	padding-top: 8px;
}


.view-events-calendar {
	margin-top: 20px;
}
  
.view .date-nav-wrapper .date-prev, .view .date-nav-wrapper .date-next{
	background-color: #fff;
	border-radius: 8px;
	
	position: relative;
	@include breakpoint($sm) {
		position: absolute;
	}
	
	a{
		background-color: $wcm-dark-orange;
		color: #fff;
		border: none;
		border-radius: 8px;
		padding: 0 35px;
		height: 30px;
		line-height: 30px;
		margin: 0;
	}
}

.pane-events-calendar-panel-pane-1 {

	.view-content {
		.calendar-calendar {
				width: 100%;
				overflow-y: hidden;
				overflow-x: auto;
				-ms-overflow-style: -ms-autohiding-scrollbar;
				border: 1px solid #ddd;
				-webkit-overflow-scrolling: touch;
		}
	}
}

.calendar-calendar .month-view .full td.single-day div.monthview{
	padding: 5px;
	// background: $wcm-yellow;
	border-radius: 0;
}

.calendar-calendar .month-view .full td.single-day .calendar-empty, 
.calendar-calendar .month-view .full td.single-day.empty, 
.calendar-calendar .month-view .full td.date-box.empty{
  // background-color: #fff;
  .day{
    color:$wcm-med-gray;
  }
}

// .calendar-calendar th.days{
//   background-color: #fff;
//   border: 0;
//   border-top: 7px solid #e8e9ea;
//   height: 38px;
//   line-height: 38px;
//   padding: 0;
//   margin: 0;
//   font-size: 17px;
//   font-weight: normal;
// }

.calendar-calendar .month-view .full td.single-day .inner>div {
	// border-left: 5px solid #fdc326;
}

.calendar-calendar .month-view .full td.date-box.today, .calendar-calendar .month-view .full tr td.single-day.today,
.calendar-calendar .month-view .full tr td.today, .calendar-calendar .month-view .full tr.odd td.today, .calendar-calendar .month-view .full tr.even td.today {
    border-color: $wcm-dark-orange;
}

.calendar-calendar td span.date-display-single, .calendar-calendar td span.date-display-start, .calendar-calendar td span.date-display-end, .calendar-calendar td span.date-display-separator, .calendar-calendar td a {
	// font-weight: normal;
	// font-size: 13px;
	display: inline;
}

.today .inner>div {
	border-left: none !important;
}

/* Localist Calendar */
.localist-calendar-widget {
	margin-top: 20px;

	.localist_minicalendar {
			position: relative;
	}

	.localist_minicalendar_page_heading {
			h1 {
					margin-bottom: 0;
					margin-top: 0;
					font-size: 1.5rem !important;
					padding-bottom: 0;
					padding-top: 4px;
					color: $wcm-black !important;
			}

			border: 1px solid $wcm-border-gray;
			height: 40px !important;
			border-bottom: none;
			color: $wcm-black;
			margin-bottom: 0;
	}

	.localist_minicalendar .localist_minicalendar_pager a {
			// text-indent: -9999px;
			background: none !important;
			position: relative;
			font-size: 0;
			padding: 13px 10px;
			color: $wcm-red;
			border-bottom: none;

			&:before {
					color: $wcm-red;
					font-size: 1.3rem;
					content: ' \e810';
					font-family: "fontello";
					font-style: normal;
					font-weight: normal;
					speak: none;
					display: inline-block;
					text-decoration: inherit;
					width: 1em;
					margin-right: .2em;
					text-align: center;
					font-variant: normal;
					text-transform: none;
					line-height: 1em;
					margin-left: .2em;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
			}

			&.right {
					&:before {
					color: $wcm-red;
					font-size: 1.3rem;
					content: ' \e802';
					font-family: "fontello";
					font-style: normal;
					font-weight: normal;
					speak: none;
					display: inline-block;
					text-decoration: inherit;
					width: 1em;
					margin-right: .2em;
					text-align: center;
					font-variant: normal;
					text-transform: none;
					line-height: 1em;
					margin-left: .2em;
					-webkit-font-smoothing: antialiased;
					-moz-osx-font-smoothing: grayscale;
					}
			}
	}

	.localist_minicalendar_minicalendar {
			width: 100%;
			border: 1px solid $wcm-border-gray;

			.localist_minicalendar_dimday a {
					color: $wcm-med-gray;
					&:hover {
							color: $wcm-white;
					}
			}

			caption {
					display: none;
			}

			tbody {
					border-top: none;
			}

			th {
					background: #fff;
					color: #000;
					border: none;
					padding: 7px 0;
					font-size: 13px;
					font-weight: 600;
							/*&:last-child {
									border-right: 1px solid #ccc;
							}*/
			}

			td {
					background-color: $wcm-bg-gray;
					border: none;
					font-weight: normal;
					padding: 4px 0;

					&.localist_minicalendar_today {
							@include breakpoint( (max: 359px) ) {
									background-color: $wcm-dark-orange;
									a {
											width: auto;
											height: auto;
											color: $wcm-white;
									}
							}
							a {
									@include breakpoint( (min: 360px) ) {
											background-color: $wcm-dark-orange;
											color: $wcm-white;
											font-weight: normal;
									}
							}
					}

					a {
							@include breakpoint( (max: 359px) ) {
									width: auto;
									height: auto;
									line-height: inherit;
							}
							height: 45px;
							line-height: 45px;
							width: 45px;
							display: inline-block;
							border-radius: 45px;
							border: none;

							&:hover {
									background-color: $wcm-dark-orange;
									font-weight: normal;
							}
					}
			}
	}

	.localist_minicalendar_events {
			margin-top: 20px;
			#lw {
				font-family: inherit;

					ul {
							padding: 0;

							li.lwe {
									padding: 0;
									border-bottom: none;
									margin-bottom: 10px;

									// padding-left: 40px;
									position: relative;
									padding-bottom: 20px;
									margin-bottom: 25px;
									border-bottom: 1px solid $wcm-border-gray;

									.lwd, .lwl {
											font-size: inherit;
											line-height: inherit;
									}

									.lwi0 {
											float: right;
											padding: 0;
											margin-left: 10px;
											margin-bottom: 15px;

											.lwi {
													padding: 0;
													border: none;
											}
									}

									.lwn0 {
											width: 100%;
											height: auto;
											display: block;
											text-align: left;
											padding: 0;
											margin-bottom: 10px;
											float: none;
											color: $wcm-med-gray;
											font-size: 13px;
											font-weight: 600;
											text-transform: uppercase;
									}
							}
					}
			}
	}
}